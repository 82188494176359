import React from 'react';
import styled from 'styled-components';
import A10 from '../../img/A10.png'
import A11 from '../../img/A11.png'
import A12 from '../../img/A12.png'
import A13 from '../../img/A13.png'
import A14 from '../../img/A14.png'

const SectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3% 20%;
  background-color: var(--dark);
`;

const Image = styled.img`
filter: grayscale(100%);
  width: 20%;
  height: auto;
`;

const Section = () => {
  return (
    <SectionContainer>
      <Image src={A10} alt="Image 1" />
      <Image src={A11} alt="Image 2" />
      <Image src={A12} alt="Image 3" />
      <Image src={A13} alt="Image 4" />
      <Image src={A14} alt="Image 5" />
    </SectionContainer>
  );
};

export default Section;
