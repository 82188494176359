import React, { useState } from 'react';
import styled from 'styled-components';
import EmaarBeach from "../../img/emaar-beachfront.jpg"
import PalmJumeeirah from "../../img/palm_jumeirah.jpg"
import PortDe from "../../img/port-de-la-mer.jpg"

import LuxuryPenthouse from "../../img/luxury-penthous.jpg"
import Marina from "../../img/marina-living.jpg"
import Downtown from "../../img/downtown.jpg"

import Dg2 from "../../img/Dg21W_NZw.jpeg"
import Czd from "../../img/cZd45TLbp.jpeg"
import Acn from "../../img/acnvCjQjYt.jpg"

const Main = styled.section`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10%;
    background-color: var(--dark);
    @media (max-width:1500px) {
        padding: 10% 1%;
        }
    @media (max-width:900px) {
        padding: 10% 1.5%;
    }
`

const Section = styled.div`
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    min-width: 80%;
    @media (max-width:1200px) {
        width: 100%;
        }
    @media (max-width:900px) {
        padding: 20px 1px;
        flex-direction: column;
        }
  
`;

const Options = styled.div`
  width: 25%;
  background-color: var(--dark);
  @media (max-width:900px) {
        width: 100%;
    }
`;

const OptionItem = styled.button`
    display: block;
    width: 100%;
    padding: 20px 25px;
    margin-bottom: 10px;
    border: 1px solid;
    border-color: ${(props) => (props.selected ? '#d4d4d4' : 'transparent')};
    border-right: none;
    color: var(--white    );
    cursor: pointer;
    text-align: left;
    font-size: 30px;
    font-weight: 200;
    background-color: var(--dark);
    @media (max-width:900px) {
        border: 1px solid;
        border-color: ${(props) => (props.selected ? '#d4d4d4' : 'transparent')};
    }
`;

const Boxes = styled.div`
    width: 80%;
    height:  auto;
    min-height: 500px;
    display: flex;
    border: 1px solid #d4d4d4;
    padding: 20px 10px;
    @media (max-width:900px) {
          width: 100%;
          border: none;
          padding: 20px 0px;
    }
`;

const Box = styled.div`
    height: auto;
    min-height: 500px;
    flex: 1;
    padding: 20px;
    background-color: #f0f0f0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 8px;
    @media (max-width:900px) {
        min-height: 600px;
  }

`;
const InnerBox = styled.section`
padding: 20px;
border-right: 1px solid white;
border-left: 1px solid white;
border-top: 1px solid white;
min-height: 100%;
position: relative;
display: flex;
justify-content: center;
`
const TextLarge = styled.h1`
color: var(--white);
font-size: 20px;
`
const TextSmall = styled.h6`
position: absolute;
bottom: 10px;
color: green;
font-size: 15px;
`

const Content = styled.p`
  font-size: 16px;
  color: #333;
  display: flex;
  width: 100%;
  justify-content: space-around;
  @media (max-width:900px) {
        flex-direction: column;
        gap: 20px;
    }
`;

function YourComponent() {
    const [selectedOption, setSelectedOption] = useState('option1');

    const handleOptionClick = (option) => {
        setSelectedOption(option);
    };

    return (
        <Main>
        <Section>
            <Options>
                <OptionItem
                    selected={selectedOption === 'option1'}
                    onClick={() => handleOptionClick('option1')}
                >
                    Penthouse
                </OptionItem>
                <OptionItem
                    selected={selectedOption === 'option2'}
                    onClick={() => handleOptionClick('option2')}
                >
                    Apartment
                </OptionItem>
                <OptionItem
                    selected={selectedOption === 'option3'}
                    onClick={() => handleOptionClick('option3')}
                >
                    Townhouses
                </OptionItem>
                <OptionItem
                    selected={selectedOption === 'option4'}
                    onClick={() => handleOptionClick('option4')}
                >
                    Villas
                </OptionItem>
            </Options>
            <Boxes>
                    {selectedOption === 'option1' && (
                        <Content><Box style={{backgroundImage:`url(${EmaarBeach})`}}>
                            <InnerBox>
                                <TextLarge>Emaar Beachfront</TextLarge>
                                <TextSmall>Learn More</TextSmall>
                            </InnerBox>
                        </Box>
                            <Box style={{backgroundImage:`url(${PalmJumeeirah})`}}>
                            <InnerBox>
                                <TextLarge>Palm Jumeirah</TextLarge>
                                <TextSmall>Learn More</TextSmall>
                            </InnerBox>
                            </Box>
                            <Box style={{backgroundImage:`url(${PortDe})`}}>
                            <InnerBox>
                                <TextLarge>Palm De La Mer</TextLarge>
                                <TextSmall>Learn More</TextSmall>
                            </InnerBox>
                            </Box></Content>
                    )}
                    {selectedOption === 'option2' && (
                        <Content><Box style={{backgroundImage:`url(${LuxuryPenthouse})`}}>
                        <InnerBox>
                            <TextLarge>Emaar Beachfront</TextLarge>
                            <TextSmall>Learn More</TextSmall>
                        </InnerBox>
                    </Box>
                        <Box style={{backgroundImage:`url(${Marina})`}}>
                        <InnerBox>
                            <TextLarge>Palm Jumeirah</TextLarge>
                            <TextSmall>Learn More</TextSmall>
                        </InnerBox>
                        </Box>
                        <Box style={{backgroundImage:`url(${Downtown})`}}>
                        <InnerBox>
                            <TextLarge>Palm De La Mer</TextLarge>
                            <TextSmall>Learn More</TextSmall>
                        </InnerBox>
                        </Box></Content>
                    )}
                    {selectedOption === 'option3' && (
                        <Content><Box style={{backgroundImage:`url(${Dg2})`}}>
                        <InnerBox>
                            <TextLarge>Emaar Beachfront</TextLarge>
                            <TextSmall>Learn More</TextSmall>
                        </InnerBox>
                    </Box>
                        <Box style={{backgroundImage:`url(${Czd})`}}>
                        <InnerBox>
                            <TextLarge>Palm Jumeirah</TextLarge>
                            <TextSmall>Learn More</TextSmall>
                        </InnerBox>
                        </Box>
                        <Box style={{backgroundImage:`url(${Acn})`}}>
                        <InnerBox>
                            <TextLarge>Palm De La Mer</TextLarge>
                            <TextSmall>Learn More</TextSmall>
                        </InnerBox>
                        </Box></Content>
                    )}
                    {selectedOption === 'option4' && (
                        <Content><Box style={{backgroundImage:`url(${PalmJumeeirah})`}}>
                        <InnerBox>
                            <TextLarge>Emaar Beachfront</TextLarge>
                            <TextSmall>Learn More</TextSmall>
                        </InnerBox>
                    </Box>
                        <Box style={{backgroundImage:`url(${EmaarBeach})`}}>
                        <InnerBox>
                            <TextLarge>Palm Jumeirah</TextLarge>
                            <TextSmall>Learn More</TextSmall>
                        </InnerBox>
                        </Box>
                        <Box style={{backgroundImage:`url(${PortDe})`}}>
                        <InnerBox>
                            <TextLarge>Palm De La Mer</TextLarge>
                            <TextSmall>Learn More</TextSmall>
                        </InnerBox>
                        </Box></Content>
                    )}
            </Boxes>
        </Section>
        </Main>
    );
}

export default YourComponent;
