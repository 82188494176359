import React from 'react';
import styled from 'styled-components';

const SectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5%;
  background-color: var(--dark);
  color: var(--white);

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Section = styled.div`
  width: 30%;
  text-align: center;
  margin: 10px;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const LargeHeading = styled.h2`
  font-size: 24px;
  margin-bottom: 10px;

`;

const SmallHeading = styled.h3`
  font-size: 16px;
  line-height: 30px;
  font-weight: normal;
  margin: 15px 0;
  text-transform: capitalize; 
`;

function YourComponent() {
  return (
    <SectionContainer>
      <Section>
        <LargeHeading>Off plan project</LargeHeading>
        <SmallHeading> Gulfa towers </SmallHeading>
        <SmallHeading> Gulf tower </SmallHeading>
        <SmallHeading> Rose tower </SmallHeading>
        <SmallHeading> Ajman creek towers </SmallHeading>
        <SmallHeading> Ameera village </SmallHeading>
        <SmallHeading> Azha community </SmallHeading>
        <SmallHeading> Garden residence </SmallHeading>
      </Section>
      <Section>
        <LargeHeading>Ready projects</LargeHeading>
        <SmallHeading> Orient towers </SmallHeading>
        <SmallHeading> Oasis tower </SmallHeading>
        <SmallHeading> Conquerer tower </SmallHeading>
        <SmallHeading> Al -Rashedya towers </SmallHeading>
        <SmallHeading> Falcon towers </SmallHeading>
        <SmallHeading> Horizon towers </SmallHeading>
        <SmallHeading> Al-Nuaemya towers </SmallHeading>
        <SmallHeading> Al -khor towers </SmallHeading>
        <SmallHeading> Ajman Corniche residence </SmallHeading>
        <SmallHeading> Corniche tower </SmallHeading>
        <SmallHeading> City towers </SmallHeading>
        <SmallHeading> Lilies tower </SmallHeading>
        <SmallHeading> Lavender tower </SmallHeading>
        <SmallHeading> Gold crest dreams </SmallHeading>
        <SmallHeading> Mr tower </SmallHeading>
        <SmallHeading> Lake tower c4 </SmallHeading>
        <SmallHeading> Paradise lake towers </SmallHeading>
        <SmallHeading> Ajman uptown villas </SmallHeading>
        <SmallHeading> Yasmeen villas </SmallHeading>
        <SmallHeading> Al zahya villas </SmallHeading>
        <SmallHeading> Al zorah </SmallHeading>
      </Section>
      <Section>
        <LargeHeading>Popular Searches</LargeHeading>
        <SmallHeading> Properties for sale in Ajman</SmallHeading>
        <SmallHeading> Properties for sale in Dubai</SmallHeading>
        <SmallHeading> Properties for sale in Dubai Marina</SmallHeading>
        <SmallHeading> Properties for sale in Sharjah</SmallHeading>
        <SmallHeading> Properties for sale in Abu Dhabi</SmallHeading>
      </Section>
    </SectionContainer>
  );
}

export default YourComponent;
