import React from 'react';
import styled from 'styled-components';
import Downtown from "../../img/downtown.jpg"
import Marina from "../../img/marina-living.jpg"
import PalmJumeeirah from "../../img/palm_jumeirah.jpg"
import PortDe from "../../img/port-de-la-mer.jpg"
import EmaarBeach from "../../img/emaar-beachfront.jpg"

const SectionContainer = styled.div`
  text-align: center;
  min-height: 60vh;
  padding: 5% 6%;
  background-color: var(--dark);
  color: var(--white);
  @media (max-width: 900px) {
    padding: 5% 2%;
  }
`;

const SmallHeading = styled.h2`
font-size: 16px;
line-height: 26px;
text-align: center;
margin: 10px;
margin-top: 80px;
font-weight: 500;
letter-spacing: 1.1px;
text-transform: uppercase;
color: var(--brown);
`;

const LargeHeading = styled.h1`
  font-size: 34px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 30px;
`;

const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 50px;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const ImageSection = styled.div`
  width: 19.5%;
  margin-bottom: 20px;
  min-height: 400px;

  @media (max-width: 900px) {
    width: 100%;
  }
`;
const Box = styled.div`
flex: 1;
  padding: 20px;
  background-color: #f0f0f0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 2px;
  min-height: 100%;

`;
const InnerBox = styled.section`
padding: 20px;
border-right: 1px solid white;
border-left: 1px solid white;
border-top: 1px solid white;
min-height: 400px ;
position: relative;
display: flex;
justify-content: center;
`
const TextLarge = styled.h1`
color: var(--white);
font-size: 20px;
`
const TextSmall = styled.h6`
position: absolute;
bottom: 10px;
color: var(--white);
font-size: 15px;
`

function YourComponent() {
  return (
    <SectionContainer>
      <SmallHeading>. Explore Dubai .</SmallHeading>
      <LargeHeading>AREA GUIDES</LargeHeading>
      <ImagesContainer>
      <ImageSection>
        <Box style={{backgroundImage:`url(${EmaarBeach})`}}>
                        <InnerBox>
                            <TextLarge>Emaar Beachfront</TextLarge>
                            <TextSmall>Learn More</TextSmall>
                        </InnerBox>
                    </Box>
        </ImageSection>
        <ImageSection>
        <Box style={{backgroundImage:`url(${PalmJumeeirah})`}}>
                        <InnerBox>
                            <TextLarge>Palm Jumeirah</TextLarge>
                            <TextSmall>Learn More</TextSmall>
                        </InnerBox>
                    </Box>
        </ImageSection>
        <ImageSection>
        <Box style={{backgroundImage:`url(${PortDe})`}}>
                        <InnerBox>
                            <TextLarge>Emaar Beachfront</TextLarge>
                            <TextSmall>Learn More</TextSmall>
                        </InnerBox>
                    </Box>
        </ImageSection>
        <ImageSection>
        <Box style={{backgroundImage:`url(${Downtown})`}}>
                        <InnerBox>
                            <TextLarge>Downtown Living</TextLarge>
                            <TextSmall>Learn More</TextSmall>
                        </InnerBox>
                    </Box>
        </ImageSection>
        <ImageSection>
        <Box style={{backgroundImage:`url(${Marina})`}}>
                        <InnerBox>
                            <TextLarge>Marina Living</TextLarge>
                            <TextSmall>Learn More</TextSmall>
                        </InnerBox>
                    </Box>
        </ImageSection>
      </ImagesContainer>
    </SectionContainer>
  );
}

export default YourComponent;
