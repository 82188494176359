import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import styled from 'styled-components';
import Exp1 from '../../img/exp1.jpg'
import Exp2 from '../../img/exp2.jpg'

const SectionContainer = styled.div`

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 5%;
  background-color: var(--dark);
  color: var(--white);

  @media (max-width: 1300px) {
    padding: 5% 2%;
  }
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    padding: 5% 2;
    padding-top: 200px;
  }
`;

const Section = styled.div`
  width: 30%;
  text-align: center;
  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Image = styled.img`
  max-width: 100%;
`;

const HeadingSmall = styled.h6`
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  margin: 10px;
  margin-top: 80px;
  font-weight: 500;
  letter-spacing: 1.1px;
  text-transform: uppercase;
  color: var(--brown);
`;

const HeadingLarge = styled.h2`
  font-size: 34px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 30px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  color: var(--white);
  background-color: var(--dark);
  &::placeholder{
    color: var(--light);
  }
  &:focus{
    outline: none;
  }
`;

const SubmitButton = styled.button`
  background-color: var(--dark);
  color: var(--white);
  padding: 10px 20px;
  border: 1px solid var(--brown);
  cursor: pointer;
  margin: 10px;
  min-width: 150px;
  color: var(--light);
  font-weight: 800;
  @media (max-width: 900px) {
    width: 50%;
    margin-top: 20px;
    margin-bottom: 70px;
  }
  @media (max-width: 500px) {
    width: 70%;
  }
`;

function SectionFive() {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        'service_huyjgef',
        'template_4be7y3q',
        formData,
        'TF7s6h0YHDNaTls7P'
      )
      .then(
        (response) => {
          console.log('Email sent:', response);
          alert('Email sent successfully!');
        },
        (error) => {
          console.error('Error sending email:', error);
          alert('Email could not be sent.');
        }
      );
  };

  return (
    <SectionContainer>
      <Section>
        <Image src={Exp1} alt="Image 1" />
      </Section>
      <Section>
        <HeadingSmall>Tired of Searching?</HeadingSmall>
        <HeadingLarge>Let Experts Do The Work</HeadingLarge>
        <form onSubmit={handleSubmit}>
          <InputWrapper>
            <Input
              type="text"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <Input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <Input
              type="tel"
              name="phone"
              placeholder="Phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </InputWrapper>
          <SubmitButton type='submit'>Get Help Now</SubmitButton>
        </form>
      </Section>
      <Section>
        <Image src={Exp2} alt="Image 2" />
      </Section>
    </SectionContainer>
  );
}

export default SectionFive;
