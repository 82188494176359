import React from 'react';
import styled from 'styled-components';
import Logo from '../img/logo.png'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';

const SectionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: var(--dark);
  color: var(--white);
  padding: 5%;
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`;

const LeftSection = styled.div`
  width: 60%;
  text-align: center;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const RightSection = styled.div`
  width: 40%;
  text-align: center;

  @media (max-width: 900px) {
    width: 100%;
    margin-top: 20px;
  }
`;

const LogoSection = styled.div`
  margin: 10px 0;
  filter: invert(100%);
`;

const LogoImage = styled.img`
  max-width: 100%;
  width: 230px;
  padding: 10px;
`;
const LogoMapSection = styled.div`
  margin: 10px 0;
`;

const LogoMapImage = styled.img`
  max-width: 100%;
`;

const SubSection = styled.section`
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 0.5px solid var(--brown);
  @media (max-width: 700px) {
    flex-direction: column;
  }
`
const SubSectionOne = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid var(--brown);
  width: 40%;
  @media (max-width: 700px) {
    width: 100%;
    justify-content: space-evenly;
  }
`
const SubSectionTwo = styled.section`
  width: 60%;
  @media (max-width: 700px) {
    width: 100%;
  }
`
const Para = styled.p`
    margin: 15px 2px;
`
const LargeText = styled.h3`
  font-size: 20px;
  margin: 10px 0;
  @media (max-width: 700px) {
    font-size: 30px;
  }
`;
const SmallText = styled.p`
  color: var(--brown) !important;
  cursor: pointer;
  font-size: 16px;
  margin: 5px 0;
  @media (max-width: 700px) {
    font-size: 20px;
    margin: 15px 0;
  }
`;
const Link = styled.a`
  color: var(--brown);
  margin: 5px;
  @media (max-width: 700px) {
    font-size: 20px;
    margin: 15px 10px;
  }
`
const BottomSection= styled.section`
display: flex;
justify-content: space-around;
align-items: center;
padding: 10px;
color: var(--light);
`
function YourComponent() {

  const phoneNumber = '0097165290993';

  const handleCallClick = () => {
      window.location.href = `tel:${phoneNumber}`;
  };

  return (
    <SectionContainer>
      <LeftSection>
        <LogoSection>
          <LogoImage src={Logo} alt="Logo" />
        </LogoSection>
        <SubSection>
          <SubSectionOne>
              <div>
                  <Para>Apartment</Para>
                  <Para>Penthouse</Para>
                  <Para>Vilass</Para>
                  <Para>Townhouses</Para>
                  <Para>Off Plans</Para>
                  <Para>Area Guides</Para>
              </div>
              <div>
              <Para>Off Plans</Para>
                  <Para>Sell</Para>
                  <Para>Rent</Para>
                  <Para>Area</Para>
                  <Para>Developers</Para>
                  <Para>Contact Us</Para>
              </div>
            </SubSectionOne>
            <SubSectionTwo>
                <div>
                    <LargeText>Contact Us</LargeText>
                    <SmallText onClick={handleCallClick}>{phoneNumber}</SmallText>
                </div>
                <div>
                    <LargeText>Write to Us</LargeText>
                    <Link href="mailto:contact@baytalnasr.com" target="_blank" >contact@baytalnasr.com</Link>
                </div>
                <div>
                    <LargeText>Follow Us</LargeText>
                    <Link href="https://www.facebook.com/baytalnasrrealestatellc11" target="_blank" ><FacebookIcon fontSize='large'/></Link>
                    <Link href="https://www.instagram.com/baytalnasrrealestatellc/" target="_blank" ><InstagramIcon fontSize='large'/></Link>
                    <Link href="https://www.tiktok.com/@baytatnasrrealestatellc?_t=8ecb9LoO8QM&_r=1" target="_blank" ><YouTubeIcon fontSize='large'/></Link>
                </div>
            </SubSectionTwo>
        </SubSection>
        <BottomSection>
            <div style={{fontSize:"13px"}}>bayt al nasr real estate llc © 2023 All Rights Reserved</div>
            <div style={{fontSize:"13px"}}>    Terms of use |Privacy Policy |Sitemap</div>
        </BottomSection>
      </LeftSection>

      <RightSection>
        <LogoMapSection>
          <LogoMapImage  src="https://onelinkestates.com/wp-content/uploads/2023/02/map.svg" alt="Image" />
        </LogoMapSection>
      </RightSection>
    </SectionContainer>
  );
}

export default YourComponent;
