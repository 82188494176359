import React from 'react';
import styled from 'styled-components';

// Styled components for styling
const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--dark);
  color: var(--white);
  padding: 5% 5% 0 5%;
  margin-top: 91px;
`;

const Heading = styled.h1`
  font-size: 70px;
  font-weight: 300;
  @media (max-width:900px){
        font-size: 40px;
    }
`;

const HorizontalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width:900px){
        flex-direction: column;
    }
`;

const InputSection = styled.div`
  flex: 1;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin: 3% 1%;

`;

const MapSection = styled.div`
  flex: 1;
`;

const MapContainer = styled.div`
  width: 100%;
  min-height: 400px;
  background-color: #f0f0f0; /* Placeholder for the map */
  border-radius: 5px;
`;
const BrownLine = styled.section`
width: ${props => props.$width};
height: 3px;
background-image: linear-gradient(to right,  var(--brown), var(--white), var(--brown));
`

function ContactSection() {
    return (
        <SectionContainer>
            <Heading>Contact us</Heading>
            <BrownLine $width="15%" />

            <HorizontalContainer>
                <InputSection>
                    <form>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <label htmlFor="name" style={{ color: "#6D6D6D" }}>Name:</label>
                            <input placeholder='Name' style={{ backgroundColor: "black", border: "1px solid #6d6d6d", padding: "10px", margin: "10px 0", color:"white" }} type="text" id="name" name="name" />
                        </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <label style={{ color: "#6D6D6D" }} htmlFor="number">Phone Number:</label>
                            <input placeholder='Number' style={{ backgroundColor: "black", border: "1px solid #6d6d6d", padding: "10px", margin: "10px 0", color:"white" }} type="tel" id="number" name="number" />
                        </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <label style={{ color: "#6D6D6D" }} htmlFor="email">Email:</label>
                            <input placeholder='Email' style={{ backgroundColor: "black", border: "1px solid #6d6d6d", padding: "10px", margin: "10px 0", color:"white" }} type="email" id="email" name="email" />
                        </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <label style={{ color: "#6D6D6D" }} htmlFor="message">Message:</label>
                            <textarea  placeholder='Message' style={{ backgroundColor: "black", border: "1px solid #6d6d6d", padding: "10px", margin: "10px 0", color:"white"}} id="message" name="message" rows="4" />
                        </div>
                        <button  style={{cursor:"pointer" ,border: "none", backgroundColor: "black", color: "white", width: "100%", fontSize: "17px", margin: "3%" }}>send</button>
                    </form>
                </InputSection>

                <MapSection>
                    <MapContainer>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3604.6859725050476!2d55.49762799999999!3d25.381839!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ef5f76669d5e9f3%3A0xf1add658cd2e7fe7!2sBayt%20Al%20Nasr%20real%20Estate%20llc!5e0!3m2!1sen!2som!4v1693476565226!5m2!1sen!2som" width="100%" title='Bayt Al Nasr Real Estate LLC Location' height="400px" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </MapContainer>
                </MapSection>
            </HorizontalContainer>
        </SectionContainer>
    );
}

export default ContactSection;
