import React, { useState } from 'react'
import styled from "styled-components"
import HamIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DoubleSidedRangeSlider from './slider';

import './style.css';

const Section = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 50%;
    z-index: 2;
`
const P = styled.p`
    color: var(--brown);
    font-size: 18px;
    margin: 5px;
    line-height: 26px;
    font-weight: 500;
    letter-spacing: 1.1px;
    //  text-shadow: 2px 2px 5px rgba(100, 100, 100, 0.5);
    @media (max-width:900px) {
            font-size: 15px;
        }
    `
const H1 = styled.h1`    
    color: var(--white);
    font-size: 48px;
    font-weight: 600;
    line-height: 1.2;
    margin: 5px;
    @media (max-width:900px) {
        font-size: 30px;
        text-align: center;
        }
    `
const SearchContainer = styled.section`
    display: flex;
    margin-top: 50px;
    width: 100%;
    justify-content: center;
    min-height: 60px;
    @media (max-width:900px) {
        flex-direction: column;
        gap: 20px;
    }

`
const Input = styled.input`
    background-color: var(--minDark);
    font-size: 15px;
    font-weight: 400;
    border: none;
    flex: 1;
    display: flex;
    justify-content: center;
    padding-left: 15px;
    margin-right: 1px;
    &::placeholder {
        color: var(--light);
    }
    &:focus{
        outline: none;
        background-color: var(--white);
    }
    @media (max-width:900px) {
            padding: 20px;
    }
    `
const Label = styled.label`margin: 10px 0px`

const HamInnerOne = styled.section`
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 3%;`

const HamInnerTwo = styled.section`
    display: flex;
    padding: 20px;`

const Select = styled.select`
    background-color: var(--minDark);
    font-size: 15px;
    font-weight: 400;
    color: var(--light);
    border: none;
    flex: 1;
    display: flex;
    justify-content: center;
    padding: 15px;
    margin-right: 1px;
    &:focus{
    outline: none;
        background-color: var(--white);
    }
    @media (max-width:900px) {
    padding: 20px;
    }`
const Option = styled.option`
    background: var(--brown);
    color: var(--white);
    `

const HamContainer = styled.section`
    position: relative;
    background-color: var(--minDark);
    flex: .3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    cursor: pointer;
    z-index: 999;`

const Ham = styled.span`
    font-size: 14px;
    color: #878c9f;
    cursor: pointer;`

const HamOptions = styled.section`
    position: absolute;
    top: 80px;
    right: 0;
    background-color: var(--white);
    min-width: 30vw;
    z-index: 99;
    border-radius: 10px;`

const Button = styled.button`
    background-color: #D3A188;
    color: var(--white);
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    border: none;
    flex: 1;
    z-index: 2;
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 10px 10px 0;
    @media (max-width:900px) {
        padding: 20px;
        margin: 0 30%;
    }`

const SectionOne = () => {

    const [HamShow, SetHamShow] = useState(true)

  return (
    <>
        <Section>
            <P>The Best Properties Place </P>
            <H1>Let`s find the perfect place</H1>
            <SearchContainer>
                <Input type='text' placeholder='Enter keyword here...' />
                <Select>
                    <Option hidden>Select Type</Option>
                    <Option value="sell">Sell</Option>
                    <Option value="rent">Rent</Option>
                </Select>
                <Input type='text' placeholder='Select Location' />
                <HamContainer>
                    <Ham><HamIcon fontSize='medium' className='hamIcon' onClick={() => { SetHamShow(!HamShow) }}/>
                        <HamOptions hidden={HamShow}>
                            <HamInnerOne>
                                <Label htmlFor='tv'>Tv Cable</Label>
                                <Input type='checkbox' id='tv' className='hamCheckbox' />

                                <Label htmlFor='ac'>Air Conditioning</Label>
                                <Input type='checkbox' id='ac' className='hamCheckbox' />
                                <Label htmlFor='bbq'>Barbeque</Label>
                                <Input type='checkbox' id='bbq' className='hamCheckbox' />

                                <Label htmlFor='gym'>Gym</Label>
                                <Input type='checkbox' id='gym' className='hamCheckbox' />

                                <Label htmlFor='swimming'>Swimming Pool</Label>
                                <Input type='checkbox' id='swimming' className='hamCheckbox' />

                                <Label htmlFor='laundary'>Laundary</Label>
                                <Input type='checkbox' id='laundary' className='hamCheckbox' />

                                <Label htmlFor='mr'>Maids Room</Label>
                                <Input type='checkbox' id='mr' className='hamCheckbox' />

                                <Label htmlFor='os'>Outdoor Shower</Label>
                                <Input type='checkbox' id='os' className='hamCheckbox' />

                                <Label htmlFor='lawn'>Lawn</Label>
                                <Input type='checkbox' id='lawn' className='hamCheckbox' />

                                <Label htmlFor='washer'>Washer</Label>
                                <Input type='checkbox' id='washer' className='hamCheckbox' />
                            </HamInnerOne>
                            <HamInnerTwo>
                                <Select className='hamSelect'>
                                    <Option hidden>Bedroom</Option>
                                    <Option>1</Option>
                                    <Option>2</Option>
                                    <Option>3</Option>
                                </Select>
                                <Select className='hamSelect'>
                                    <Option hidden>Bath</Option>
                                    <Option>1</Option>
                                    <Option>2</Option>
                                    <Option>3</Option>
                                </Select>
                                <DoubleSidedRangeSlider />
                            </HamInnerTwo>

                        </HamOptions>
                    </Ham>
                </HamContainer>
                <Button>
                    Search
                    <SearchIcon className='searchIcon' />
                </Button>
            </SearchContainer>
        </Section>
    </>
  );
}

export default SectionOne;
