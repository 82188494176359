import Navbar from "./components/Navbar";
import { Route, Routes } from "react-router-dom"
import Home from "./components/home/Home";
import Footer from "./components/Footer"
import Properties from "./components/properties/Properties";
import Rent from "./components/rent/Rent";
import Sell from "./components/sell/Sell"
import About from "./components/about/About";
import Contact from "./components/contact/Contact"
import WhatsAppButton from "./components/WhatsAppButton";

function App() {
  return (
    < >
      <Navbar/>
      <WhatsAppButton/>
      <Routes>
        <Route exact path = '/' element={<Home/>}/>
        <Route exact path = '/properties' element={<Properties/>}/>
        <Route exact path = '/rent' element={<Rent/>}/>
        <Route exact path = '/sell' element={<Sell/>}/>
        <Route exact path = '/about' element={<About/>}/>
        <Route exact path = '/contact' element={<Contact/>}/>
      </Routes>
    <Footer/>
    </>
  );
}

export default App;
