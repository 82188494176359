import React from 'react';
import styled from 'styled-components';
import A1 from "../../img/CEO.jpg";
import A2 from "../../img/Hassan-Shah.jpg";
import A3 from "../../img/A3.jpeg";
import A4 from "../../img/A4.jpeg";
import A5 from "../../img/A5.jpeg";

const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: var(--dark);
    color: var(--white);
    padding: 5% 15%;
    @media(max-width:900px){
        padding: 5% 1%;
    }
`;

const Image = styled.img`
    height: 400px;
    width: 100%;
    margin-bottom: -10px;
    object: fit-content;
    @media(max-width:900px){
        width: 500px;
    }
    @media(max-width:550px){
        width: 100%;
        height: 350px;
    }
`;

const H1 = styled.h1`
    font-size: 30px;
    margin-bottom: 3%;
`;

const LargeHeading = styled.h1`
    font-size: 25px;
    margin: 10px;
    font-weight: 500;
`;

const SmallHeading = styled.h2`
    font-size: 18px;
    margin: 10px 0;
    color: var(--brown);
`;

const SubSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media(max-width:900px){
        flex-direction: column;
    }

`;

const LeftSubSection = styled.div`
    flex: 1;
    text-align: left;
    @media(max-width:900px){
        margin: 3% 10%;
    }

    @media(max-width:550px){
        margin: 2%;
    }
`;

const RightSubSection = styled.div`
    flex: 1;
    text-align: center;
    @media(max-width:900px){
        margin: 3%;
    }

`;

const Paragraph = styled.p`
    font-size: 14px;
    font-weight: 500;
    margin: 10px;
    line-height: 30px;
`;

const BrownLine = styled.section`
    width: ${props => props.$width};
    height: 3px;
    background-image: linear-gradient(to right,  var(--brown), var(--white), var(--brown));
    margin: 10px;
`;

const BrownDot = styled.section`
    display: inline-block;
    width: 7px;
    height: 7px;
    border-radius: 5px;
    margin-right: 5px;
    background-color: var(--brown);
`;

const Section = () => {
    return (
        <SectionContainer>
            <SmallHeading>. BAYT AL NASR REAL ESTATE LLC .</SmallHeading>
            <H1>OUR VALUES</H1>

            <SubSection>
                <RightSubSection>
                    <Image
                        width= '100%'
                        src={A1}
                        alt=''
                    />
                </RightSubSection>
                <LeftSubSection>
                    <LargeHeading>FAHEEM AHMAD KHAN - FOUNDER & CEO</LargeHeading>
                    <BrownLine $width='20%'/>
                    <Paragraph>
                        We envision BAYT AL NASR REAL ESTATE LLC to be the pre-eminent real estate service provider in the Emirates and the most coveted place for professionals seeking to enhance their career. We strive to build lifelong partnerships with our clients through integrity and mutual understanding. As we grow, we’re committed to these principles and our core values which have always served our company and clients.
                    </Paragraph>
                </LeftSubSection>
            </SubSection>

            <SubSection>
                <LeftSubSection>
                    <LargeHeading>SYED HASSAN SHAH GILLANI - DIRECTOR & CEO</LargeHeading>
                    <BrownLine $width='20%'/>
                    <Paragraph>We believe in excellence, innovation, and hard work that supersedes traditional boundaries to achieve tangible results and success and be a valuable addition to the Real Estate sector in the country. The quality of our service and the solutions we provide are never compromised at BAYT AL NASR REAL ESTATE LLC. We are a company of doers. Constantly raising the bar higher is what we believe in.
                    </Paragraph>
                </LeftSubSection>
                <RightSubSection>
                    <Image
                        width= '100%'
                        src={A2}
                        alt=''
                    />
                </RightSubSection>
            </SubSection>

            <SubSection>
                <RightSubSection>
                    <Image
                        width= '100%'
                        src={A3}
                        alt=''
                    />
                </RightSubSection>
                <LeftSubSection>
                    <LargeHeading>OUR CUSTOMERS ARE OUR PRIORITY</LargeHeading>
                    <BrownLine $width='20%'/>
                    <Paragraph>
                         <BrownDot/> Listening, anticipating and serving customers' wants and needs</Paragraph>
                    <Paragraph>
                         <BrownDot/> Be creative, take risks intelligently, and move fas</Paragraph>
                    <Paragraph>
                         <BrownDot/> Harness the power of global vision, relationships, collaboration
                    </Paragraph>
                </LeftSubSection>
            </SubSection>

            <SubSection>
                <LeftSubSection>
                    <LargeHeading>WE GROW TOGETHER EXCELENCE</LargeHeading>
                    <BrownLine $width='20%'/>
                    <Paragraph>
                         <BrownDot/> Be present and available, cooperate with others and be open to feedback</Paragraph>
                    <Paragraph>
                         <BrownDot/> Be humble, communicate, and trust others to do what they do best </Paragraph>
                    <Paragraph>
                         <BrownDot/> Seeking and embracing all kinds of differences in people, ideas and experiences 
                    </Paragraph>
                    <Paragraph>
                         <BrownDot/> Property Insurance Support
                    </Paragraph>
                </LeftSubSection>
                <RightSubSection>
                    <Image
                        width= '100%'
                        src={A4}
                        alt=''
                    />
                </RightSubSection>
            </SubSection>

            <SubSection>
                <RightSubSection>
                    <Image
                        width= '100%'
                        src={A5}
                        alt=''
                    />
                </RightSubSection>
                <LeftSubSection>
                    <LargeHeading>WE EXHIBIT ONLY EXCELENCE</LargeHeading>
                    <BrownLine $width='20%'/>
                    <Paragraph>
                         <BrownDot/> Set and achieve aggressive goals </Paragraph>
                    <Paragraph>
                         <BrownDot/> Own, celebrate success and take responsibility for results </Paragraph>
                    <Paragraph>
                         <BrownDot/> Make clear choices, anticipate changing conditions and plan for the future
                    </Paragraph>
                    <Paragraph>
                         <BrownDot/> Challenge yourself to push boundaries, think out of the box, and excel as an organization
                    </Paragraph>
                </LeftSubSection>
            </SubSection>
        </SectionContainer>
    );
};

export default Section;