import React from 'react'
import styled from "styled-components"
import BgImg from "../../img/homebg.jpg"
import SectionOne from './SectionOne'
import SectionTwo from './SectionTwo'
import SectionThree from './SectionThree'
import SectionFour from './SectionFour'
import SectionFive from './SectionFive'
import SectionSix from './SectionSix'
import SectionSeven from './SectionSeven'

const Main = styled.section`
    background-image: url(${BgImg});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    // background-attachment: fixed;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    background-color: rgba(0, 0, 0, 0.2);
    background-blend-mode: multiply;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 91px;
`

const Home = () => {
    return (
        <>
            <Main>
                <SectionOne/>
            </Main>
                <SectionTwo/>
                <SectionThree/>
                <SectionFour/>
                <SectionFive/>
                <SectionSix/>
                <SectionSeven/>
        </>
    )
}

export default Home
