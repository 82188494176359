import React from 'react';
import styled from 'styled-components';
import Downtown from "../../img/downtown.jpg"
import Marina from "../../img/marina-living.jpg"
import LuxuryPenthouse from "../../img/luxury-penthous.jpg"
import PalmJumeeirah from "../../img/palm_jumeirah.jpg"
import PortDe from "../../img/port-de-la-mer.jpg"
import EmaarBeach from "../../img/emaar-beachfront.jpg"

const SectionContainer = styled.div`
  text-align: center;
  min-height: 60vh;
  padding: 6%;
  background-color: var(--dark);
  color: var(--white);
  @media (max-width: 1200px) {
    padding: 3%;
  }
  @media (max-width: 900px) {
    padding: 3% 0;
  }
`;

const SmallHeading = styled.h2`
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  margin: 10px;
  font-weight: 500;
  letter-spacing: 1.1px;
  text-transform: uppercase;
  color: var(--brown);
`;

const LargeHeading = styled.h1`
  font-size: 34px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 30px;
  text-transform: uppercase;
  @media (max-width:900px) {
        font-size: 25px;
    }
`;

const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 60px;
  height: 75vh;
  @media (max-width: 900px) {
    height: auto;
    flex-direction: column;
  }
`;

const ImageSection = styled.div`
  width: 19.2%;
  margin-bottom: 20px;
  height: 100%;
  @media (max-width: 900px) {
    width: 100%;
    height: 500px;
    margin-bottom: -110px;
  }
`;
const Box = styled.div`
height: 100%;
  flex: 1;
  padding: 10px 15px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.3);
  background-blend-mode: multiply;
`;
const InnerBox = styled.section`
height: 100%;
  padding: 10px 5px;
  border: 1px solid rgba(255,255,255,.3);
  border-bottom: none;
  position: relative;
  display: flex;
  justify-content: center;
  `
const TextLarge = styled.h1`
  color: var(--white);
  font-size: 20px;
  font-weight: 600;
  `
const TextSmall = styled.h6`
  position: absolute;
  bottom: 40px;
  color: var(--white);
  font-size: 15px;
  `
const Image = styled.img`
  width: 100%;
  height: 45.5%;
  margin-bottom: 42px;
  position: relative;
  filter: brightness(80%); /* Increase or decrease the percentage for brightness */
  @media (max-width: 900px) {
    margin: 0;
  }
  @media (max-width: 386px) {
    width: 50%;
    height: 40.5%;
  }
`;


const DoubleImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  @media (max-width: 900px) {
    flex-direction: row;
    padding:0 10px;
  }
`;

function SectionFour() {
  return (
    <SectionContainer>
      <SmallHeading>. Curated Lists .</SmallHeading>
      <LargeHeading>Communities</LargeHeading>
      <ImagesContainer>
        <ImageSection>
        <Box style={{backgroundImage:`url(${Downtown})`}}>
                        <InnerBox>
                            <TextLarge>Downtown Living</TextLarge>
                            <TextSmall>Learn More</TextSmall>
                        </InnerBox>
                    </Box>
        </ImageSection>
        <ImageSection>
          <DoubleImageContainer>
            <Image height='300px' width='100%' src={PortDe} alt="Image 2.1" />
            <Image height= '300px' width='100%' src={EmaarBeach} alt="Image 2.2" />
          </DoubleImageContainer>
        </ImageSection>
        <ImageSection>
        <Box style={{backgroundImage:`url(${Marina})`}}>
                        <InnerBox>
                            <TextLarge>Marina Living</TextLarge>
                            <TextSmall>Learn More</TextSmall>
                        </InnerBox>
                    </Box>
        </ImageSection>
        <ImageSection>
          <DoubleImageContainer>
            <Image height='300px' width='100%' src={PalmJumeeirah} alt="Image 4.1" />
            <Image height='300px' width='100%' src={Marina} alt="Image 4.2" />
          </DoubleImageContainer>
        </ImageSection>
        <ImageSection>
        <Box style={{backgroundImage:`url(${LuxuryPenthouse})`}}>
                        <InnerBox>
                            <TextLarge>Luxury PentHouses</TextLarge>
                            <TextSmall>Learn More</TextSmall>
                        </InnerBox>
                    </Box>
        </ImageSection>
      </ImagesContainer>
    </SectionContainer>
  );
}

export default SectionFour;
