import React from 'react'
import styled from 'styled-components'
import AboutBg from "../../img/aboutbg.png"

const Main = styled.section`

`
const FirstSection = styled.section`
  padding: 0% 0 5% 0;
  background-color: var(--dark);
  color: var(--white);
`
const SecondSection = styled.section`
  background-image: url(${AboutBg});
  color: var(--white);
  appearance: none;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.1);
  background-blend-mode: multiply;
  padding: 3% 15%;
  @media(max-width:1000px){
  padding: 3% 5%;
  }
`
const H1 = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 70px;
  font-weight: 300;
  margin-bottom: -20px;
  @media(max-width:900px){
      font-size: 40px;
    }
    @media(max-width:500px){
      font-size: 20px;
      margin-bottom: 20px;
    }
`
const H2 = styled.h2`
  margin: 2% 0;
  font-weight: 500;
  line-height: 1.2;
  @media(max-width:500px){
    font-size: 20px;
    margin-bottom: 15px;
  }
`
const H3 = styled.h3`
  line-height: 1.6;
  margin: 2% 0;
  text-align: justify;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 400;
  @media(max-width:500px){
    font-size: 16px;
    margin: 10px 0 30px 0;
  }
`
const BrownText = styled.h1`
  color: #CEA675;
    font-weight: 300;

`
const BrownLine = styled.section`
  width: ${props => props.$width};
  height: 2px;
  background-image: linear-gradient(to right,  var(--brown), var(--white), var(--brown));
`

const SectionOne = () => {
  return (
    <>
      <Main>
        <FirstSection>
            <H1>Your<BrownText>"LINK"</BrownText>to Real Estate</H1>
            <BrownLine $width='100%'/>
        </FirstSection>
        <SecondSection>
            <H2>BAYT AL NASR REAL ESTATE LLC</H2>
            <BrownLine $width='20%'/>
            <H3>Is a full-service Real Estate that provides a menu of high-quality services tailored to fit the specific needs of our clients and to maximize the profitability and potential of their assets. We recognize that Real Estate is a highly competitive industry, therefore we continually strive to exceed the expectations of our clients. We value inclusion, diversity, innovation and building a personalized experience for our customers above all else. When you select BAYT AL NASR REAL ESTATE LLC you’re selecting the best. We’re passionate about what we do. We live by our core values and assure you that your investments are in safe hand.</H3>
            <H2>MISSION</H2>
            <BrownLine $width='20%'/>
            <H3>Our passion is to listen to our customers and help them pick a safe haven, comfort zone ‘their home’. We offer a wide range of well-sorted property options at best prices. We believe the future of real estate is in reducing the barriers between buyers and developers.</H3>
            <H2>VISION</H2>
            <BrownLine $width='20%'/>
            <H3>As an independent brokerage, we are defined by our vision of being ever evolving, adaptive, dedicated, passionate service providers. Our customer centric focus and continued valuation and development of everything we do, ensures that our customers experience an uncompromised level of service.</H3>
        </SecondSection>
        <BrownLine $width='100%'/>
      </Main>
    </>
  )
}

export default SectionOne
