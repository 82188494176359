import React, { useState } from 'react';
import styled from 'styled-components';

const SliderContainer = styled.div`
  /* Your container styles here */
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SliderRow = styled.div`
  /* Your row styles here */
  display: flex;
  flex-direction: column;
    align-items: center;
    margin: 5px;
`;

const Slider = styled.input.attrs({ type: 'range' })`
  /* Your slider styles here */
  width: 100%;
`;

const ValueLabel = styled.div`
  /* Your value label styles here */
  text-align: center;
  margin-top: 10px;
  position: sticky;
  top: 0;
  background-color: white; /* To make the label readable */
`;

function DoubleSidedRangeSlider() {
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(100);

  const handleMinChange = (e) => {
    setMinValue(e.target.value);
  };

  const handleMaxChange = (e) => {
    setMaxValue(e.target.value);
  };

  function formatValueWithCommas(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return (
    <SliderContainer>
      <SliderRow>
        <ValueLabel>Sqft  0 - {formatValueWithCommas(minValue)} </ValueLabel>
        <Slider
          min={0}
          max={30000}
          value={minValue}
          onChange={handleMinChange}
        />
      </SliderRow>
      <SliderRow>
        <ValueLabel>Price  0 - {formatValueWithCommas(maxValue)} د.إ</ValueLabel>
        <Slider
          min={0}
          max={5000}
          value={maxValue}
          onChange={handleMaxChange}
        />
      </SliderRow>
    </SliderContainer>
  );
}

export default DoubleSidedRangeSlider;
