import React, {useState}from 'react'
import Logo from '../img/logo.png'
import styled from "styled-components"
import { NavLink } from "react-router-dom"
import PhoneIcon from '@mui/icons-material/LocalPhone';
import HamIcon from '@mui/icons-material/Menu';

import './navbar.css'

const Nav = styled.nav`
    width: 100%;
    background-color: var(--dark);
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 9;
    @media (max-width:900px){
        justify-content: space-evenly;
    }
`
const Left = styled.section`
    width: 40%;
    filter: invert(100%);
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width:900px){
        width: 30%;
    }
`
const Img = styled.img`
    float: right;
    width: 110px;
    padding: 10px;
    cursor: pointer;
`
const Right = styled.section`
    color: var(--white);
    float: left;
    border-left: 1px solid var(--white);
    display: flex;
    align-items: center;
    gap: 2px;
    padding-left: 5px;
    cursor: pointer;
    font-size: 17px;
    padding: 5px 2px;
`
const Center = styled.section`
    margin-right: 20px;
    z-index: 99;
    position: relative;
    @media (min-width:900px){
        display: none;
    }
    `

const CenterLarge = styled.section`
    margin-right: 20px;
    z-index: 99;
    position: relative;
    @media (max-width:900px){
        display: none;
    }
`

const Ul = styled.ul`
    display: flex;
    @media (max-width:900px){
        flex-direction: column;
        position: absolute;
        background-color: var(--light);
        border-radius: 10px;
        top: 50px;
    }
`   
const HamContainer = styled.span`
    color: var(--white);
    display: none;
    cursor: pointer;
    margin: 20px;
    z-index: 999;
    @media (max-width:900px){
        display: block;
    }
`
const Li = styled.a`
    padding: 15px;
	line-height: 30px;
    color: white;
    text-decoration: none;
    cursor: pointer;
`
const Navbar = () => {

    const [show, setShow] = useState(false);
    const phoneNumber = '0097165290993';

    const handleCallClick = () => {
        window.location.href = `tel:${phoneNumber}`;
    };

    return (
        <>
            <Nav>
                <Left>
                    <NavLink to='/'>
                        <Img src={Logo}/>
                    </NavLink>
                </Left>
                <Center hidden={!show}>
                    <Ul  id="menu">
                        <Li as={NavLink} to='/'>Home</Li>
                        <Li as={NavLink} to='/properties'>Properties</Li>
                        <Li as={NavLink} to='/rent'>Rent</Li>
                        <Li as={NavLink} to='/sell'>Sell</Li>
                        <Li as={NavLink} to='/about'>About</Li>
                        <Li as={NavLink} to='/contact'>Contact</Li>
                    </Ul>
                </Center>
                <CenterLarge>
                    <Ul  id="menu">
                        <Li as={NavLink} to='/'>Home</Li>
                        <Li as={NavLink} to='/properties'>Properties</Li>
                        <Li as={NavLink} to='/rent'>Rent</Li>
                        <Li as={NavLink} to='/sell'>Sell</Li>
                        <Li as={NavLink} to='/about'>About</Li>
                        <Li as={NavLink} to='/contact'>Contact</Li>
                    </Ul>
                </CenterLarge>
                    <HamContainer onClick={()=>{setShow(!show)}}><HamIcon fontSize='large'/></HamContainer>
                <Right onClick={handleCallClick}>
                    <PhoneIcon style={{fontSize: '30px'}}/>Call Us
                </Right>
            </Nav>
        </>
    )
}

export default Navbar
