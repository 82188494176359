import { WhatsApp } from '@mui/icons-material';
import React from 'react';
import './whatsAppButton.css';

const WhatsAppButton = () => {
    const phoneNumber = '+971508650782';

    const handleClick = () => {
        const whatsappUrl = `https://wa.me/${phoneNumber}`;
        window.open(whatsappUrl, '_blank');
    };
    return (
        <button className="whatsapp-button" onClick={handleClick}>
            <WhatsApp className="whatsapp-icon"/>
        </button>
    );
};

export default WhatsAppButton;