import React, { useState } from 'react'
import styled from "styled-components"
import Slider from '../Slider'
import HamIcon from '@mui/icons-material/Menu';
import ResetIcon from '@mui/icons-material/Autorenew';

const Main = styled.section`
    background-color: var(--dark);
    color: var(--white);
    padding: 5% 10%;
    margin-top: 91px;
    @media (max-width:900px){
        padding: 10% 0%;
    }
`
const Section = styled.section`
    display: flex;
    justify-content: space-evenly;
    @media (max-width:900px){
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`
const LeftSection = styled.section`
      box-shadow:2px 1px 13px 0px #f0ebeb;
      width: 25%;
      padding: 1%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media (max-width:900px){
        width: 80%;
    }
`
const RightSection = styled.section`
    width: 50%;
    @media (max-width:900px){
        width: 90%;
        margin: 5% 0;
    }
`
const SubSection = styled.section`
    background-color: white;
    color: red;
    padding: 3%;
    width: 90%;
    @media (max-width:900px){
        width: 100%;
    }
`
const Input = styled.input`
background-color: var(--dark);
    border: 1px solid var(--white);
    padding: 20px;
    width: 80%;
        margin: 3% 0;
    &::placeholder{
      color: var(--light);
    }
    &:focus{
      outline: none;
      background-color: var(--white);
    }
`
const Select = styled.select`
    background-color: var(--dark);
    border: 1px solid var(--white);
    padding: 20px;
    margin: 3% 0;
    width: 80%;
    color: var(--light);
    &:focus{
      outline: none;
      background-color: var(--white);
      border: 1px solid var(--dark);
    }
`
const Option = styled.option`
`
const SmallText = styled.h6`
font-size: 18px;
margin: 10px;
    
`
const LargeText = styled.h1`
font-size: 25px;
margin: 20px;
    
`

const AminitiesContainer = styled.section`
width: 95%;
padding: 10px;
margin: 10px;

`
const AminitiesHam = styled.span`
    display: flex;
    width: 100%;
    align-items: center;
`
const AminititesInnerContainerOne = styled.section`

`
const AminititesInnerContainer = styled.section`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`
const AminitiesOption = styled.section`
width: 100%;
color: var(--light);
    
`
const HamInnerTwo = styled.section`
width: 100%;
background-color: var(--white);
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
color: var(--dark);
padding: 2% 0;
margin: 3% 0;
`
const ButtonContainer = styled.section`
display: flex;
`
const Button = styled.button`
background-color: var(--dark);
margin: 10px;
border: none;
padding: 10px;
border-radius: 5px;
;
&:focus{
    outline: none;
}
&:hover{
    background-color: var(--brown);
}
`
const Rent = () => {
    const [showOption, SetShowOption] = useState(true)
    return (
    <>
    <Main>
        <Section>
            <LeftSection>
                <LargeText>Advanced Search</LargeText>
                <Input type='text' placeholder='What are you looking for?'/>
                <Select>
                    <Option>Property Type</Option>
                    <Option>Sell</Option>
                    <Option>Rent</Option>
                </Select>
                <Select>
                    <Option>All Categories</Option>
                    <Option>Apertment</Option>
                    <Option>Commercial</Option>
                    <Option>Office</Option>
                </Select>
                <Select>
                    <Option>All Cities</Option>
                    <Option>Dubai</Option>
                    <Option>Al Barsha</Option>
                    <Option>Al Barsha Heights</Option>
                </Select>
                <Slider max={5000}/>

                <AminitiesContainer>
                    <AminitiesHam onClick={()=>{SetShowOption(!showOption)}}><HamIcon fontSize='large'/><SmallText style={{color:'#805e39'}}>Aminities</SmallText></AminitiesHam>
                    <AminititesInnerContainerOne hidden={showOption}>
                    <AminititesInnerContainer>
                        <AminitiesOption>
                        <Input style={{width:'unset', margin:'10px'}} type='checkbox'/>TV Cable
                        </AminitiesOption>
                        <AminitiesOption>
                        <Input style={{width:'unset', margin:'10px'}} type='checkbox'/>Air Conditioning
                        </AminitiesOption>
                        <AminitiesOption>
                        <Input style={{width:'unset', margin:'10px'}} type='checkbox'/>Barbeque
                        </AminitiesOption>
                        <AminitiesOption>
                        <Input style={{width:'unset', margin:'10px'}} type='checkbox'/>Gym
                        </AminitiesOption>
                        <AminitiesOption>
                        <Input style={{width:'unset', margin:'10px'}} type='checkbox'/>Swimming Pool
                        </AminitiesOption>
                        <AminitiesOption>
                        <Input style={{width:'unset', margin:'10px'}} type='checkbox'/>Laundry
                        </AminitiesOption>
                        <AminitiesOption>
                        <Input style={{width:'unset', margin:'10px'}} type='checkbox'/>Maids Room
                        </AminitiesOption>
                        <AminitiesOption>
                        <Input style={{width:'unset', margin:'10px'}} type='checkbox'/>Outdoor Shower
                        </AminitiesOption>
                        <AminitiesOption>
                        <Input style={{width:'unset', margin:'10px'}} type='checkbox'/>Lawn
                        </AminitiesOption>
                        <AminitiesOption>
                        <Input style={{width:'unset', margin:'10px'}} type='checkbox'/>Terrace
                        </AminitiesOption>
                        <AminitiesOption>
                        <Input style={{width:'unset', margin:'10px'}} type='checkbox'/>Sauna
                        </AminitiesOption>
                        <AminitiesOption>
                        <Input style={{width:'unset', margin:'10px'}} type='checkbox'/>Washer
                        </AminitiesOption>
                        <AminitiesOption>
                        <Input style={{width:'unset', margin:'10px'}} type='checkbox'/>Furnished
                        </AminitiesOption>
                        <AminitiesOption>
                        <Input style={{width:'unset', margin:'10px'}} type='checkbox'/>Un Furnished
                        </AminitiesOption>
                    </AminititesInnerContainer>
                    <HamInnerTwo>
                                    <Select>
                                        <Option >Bedroom</Option>
                                        <Option>1</Option>
                                        <Option>2</Option>
                                        <Option>3</Option>
                                    </Select>
                                    <Select>
                                        <Option >Bath</Option>
                                        <Option>1</Option>
                                        <Option>2</Option>
                                        <Option>3</Option>
                                    </Select>
                                    <Slider max={30000} />
                                    </HamInnerTwo>
                                    <ButtonContainer>
                                        <Button style={{color:"white", width:"60%",border: '2px solid white'}}>Find Property</Button>
                                        <Button style={{color:"white", width:"30%",border: '2px solid #cca985'}}><ResetIcon/>Reset</Button>
                                    </ButtonContainer>
                    </AminititesInnerContainerOne>
                </AminitiesContainer>
            </LeftSection>
            <RightSection>
                <SubSection><SmallText style={{fontWeight:'300', textAlign:'center'}}>No Listings were found matching your selection.</SmallText> </SubSection>
            </RightSection>
        </Section>
    </Main>
      
    </>
  )
}

export default Rent
