import React, { useState } from 'react';
import styled from 'styled-components';

const SliderContainer = styled.div`
  width: 80%;
  margin: 0 auto;
`;

const Slider = styled.input`
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  height: 8px;
  border-radius: 5px;
  background: var(--brown);
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: var(--white);
    cursor: pointer;
  }
`;

const PriceDisplay = styled.div`
  text-align: right;
  font-size: 20px;
  margin-top: 10px;
`;

const PriceSlider = ({max}) => {
  const [sliderValue, setSliderValue] = useState(0); // Initial value

  const handleSliderChange = (event) => {
    const newValue = event.target.value;
    setSliderValue(newValue);
  };

  return (
    <SliderContainer>
        <PriceDisplay>0 - {sliderValue}  د.إ</PriceDisplay>
      <Slider
        type="range"
        min="0"
        max={max}
        value={sliderValue}
        onChange={handleSliderChange}
      />
    </SliderContainer>
  );
};

export default PriceSlider;
