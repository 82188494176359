import React from 'react';
import styled from 'styled-components';
import Building from '@mui/icons-material/Apartment';
import House from '@mui/icons-material/Foundation';

const SectionContainer = styled.div`
  /* Your section container styles here */
  padding: 20px;
  background-color: var(--dark);
  color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10%;
`;

const SmallHeading = styled.h4`
  /* Your small heading styles here */
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    display: flex;
    align-items: center;
    margin: 10px;
    font-weight: 500;
    letter-spacing: 1.1px;
    text-transform: uppercase;
    color: var(--brown);
`;

const LargeHeading = styled.h1`
  /* Your large heading styles here */
  font-size: 34px;
  font-weight: 500;
    line-height: 1.2;
  margin-bottom: 30px;
`;

const BoxContainer = styled.div`
  /* Your box container styles here */
  min-width: 40vw;
  display: flex;
  justify-content: space-between;
  @media (max-width:1500px) {
        flex-direction: column;
        width: 100vw;
        align-items: center;
        gap: 30px;
    }
`;

const Box = styled.div`
  /* Your box styles here */
  width: 48%; /* Adjust the width as needed */
  border: 1px solid #ccc;
  padding: 20px 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  @media (max-width:900px) {
        width: 97%;
    }
`;


const BoxText = styled.section`
  /* Your box text styles here */
  /* display: flex; */
  position: relative;
  flex: 1;
  text-align: center;
`;
const BoxTextLarge = styled.h1`
  /* Your box text styles here */
  padding-bottom: 5px;
  font-size: 40px;
  font-weight: 500;
`;
const BoxTextSmall = styled.p`
  position: absolute;
  font-weight: 600;
  bottom: -32px;
  right: 50px;
  color: #d3a188;
  background-color: var(--dark);
  `

function YourComponent() {
  return (
    <SectionContainer>
      <SmallHeading>. Wellcome to the Bayt-al-Nasr real estate llc .</SmallHeading>
      <LargeHeading>START YOUR SEARCH</LargeHeading>
      <BoxContainer>
        <Box>
          <House fontSize='large' />
          <BoxText>
          <BoxTextLarge>Buy</BoxTextLarge>
          <BoxTextSmall>Buy a home</BoxTextSmall>
          </BoxText>
        </Box>
        <Box>
          <Building fontSize='large'/>
          <BoxText>
          <BoxTextLarge>Rent</BoxTextLarge>
          <BoxTextSmall>Rent a home</BoxTextSmall>
          </BoxText>

        </Box>
      </BoxContainer>
    </SectionContainer>
  );
}

export default YourComponent;
