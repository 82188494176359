import React from 'react';
import styled from 'styled-components';
import A6 from "../../img/Sheeraz-sultan-marketing.jpg"
import A7 from "../../img/Khuram-maskeen-marketing-2.jpg"
import PhoneIcon from '@mui/icons-material/LocalPhone';

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 5% 10%;
  background-color: #f0f0f0;
  background-color: var(--dark);
  color: var(--white);
  border-bottom: 2px solid white;
  @media (max-width:500px){
    padding: 5%;
  }
`;

const LargeHeading = styled.h1`
  font-size: 34px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 10px;
  @media (max-width:500px){
    font-size: 25px;
  }
`;

const SmallHeading = styled.h2`
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  // margin: 10px;
  font-weight: 500;
  letter-spacing: 1.1px;
  text-transform: uppercase;
  color: var(--brown);
`;

const BoxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  @media (max-width:900px){
    flex-direction: column;       
  }
`;

const Box = styled.div`
  /* position: relative; */
  text-align: center;
  cursor: pointer;
  margin: 0 10%;
  @media (max-width:900px){
    margin:10% 0 ;
  }
  `;

const BoxImage = styled.img`
  border-radius: 50% 50% 50% 0%;
  width: 237px; 
  height: 237px;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
  @media (max-width:500px){
    width: 160px; 
    height: 160px;      
  }
`;

const BoxText = styled.div`
  /* position: absolute; */
  bottom: 10px;
  left: 0;
  right: 0;
  text-align: start;
  color: white;
  padding: 5px 0;
`;

const ContactContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const ContactNumber = styled.p`
  font-size: 18px;
  margin: 5px 0;
  margin: 0 10px;
`;

const Section = () => {
  return (
      <SectionContainer>
      <SmallHeading style={{color:'#B78652', fontWeight:"600"}}> Expertise Is Here </SmallHeading>
        <LargeHeading>Our Exclusive Agents</LargeHeading>

      <BoxContainer>
        <Box>
          <BoxImage src={A6} alt="Box 1" />
          <BoxText>
            <LargeHeading>Sheraz Sultan</LargeHeading>
            <SmallHeading>Marketing Manager</SmallHeading>
            <ContactContainer>
                <PhoneIcon style={{backgroundColor:'#B78652', borderRadius:'10px',padding:'3px'}}/>
                <ContactNumber>+971 54 386 4066</ContactNumber>
            </ContactContainer>
          </BoxText>
        </Box>

        <Box>
          <BoxImage src={A7} alt="Box 2" />
          <BoxText>
            <LargeHeading>Khurram Miskeen</LargeHeading>
            <SmallHeading>Marketing Manager</SmallHeading>
            <ContactContainer>
                <PhoneIcon style={{backgroundColor:'#B78652', borderRadius:'10px',padding:'3px'}}/>
                <ContactNumber>+971 50 227 0081</ContactNumber>
            </ContactContainer>
          </BoxText>
        </Box>
      </BoxContainer>


    </SectionContainer>
  );
};

export default Section;